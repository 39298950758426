import React, { useEffect, useState } from "react"
import Image1 from "../../../images/Nuestros_servicios1.jpg"
import Image2 from "../../../images/Nuestros_servicios2.jpg"
import Image3 from "../../../images/Nuestros_servicios3.jpg"
import Image4 from "../../../images/Nuestros_servicios4.jpg"
import Image5 from "../../../images/Nuestros_servicios5.jpg"
import Aos from "aos"

const SERVICES = [
  {
    id: 0,
    name: "Compra / Venta",
    text: "Nuestro compromiso es brindar un servicio excepcional en la compraventa de propiedades rurales y afines. Cada cliente recibe una atención personalizada y un asesoramiento integral, proporcionado por los propios dueños Bullrich Campos, junto a un equipo profesional altamente especializado en distintas áreas técnicas y estratégicas del negocio inmobiliario rural. En cada operación, nuestra prioridad es superar sus expectativas, ayudándolo a alcanzar el objetivo deseado de la manera más eficiente y satisfactoria.",
    image: Image1,
  },
  {
    id: 1,
    name: "Tasaciones",
    text: "El arte de la tasación combina experiencia, conocimiento técnico y visión del mercado. En Bullrich Campos, perfeccionamos esta habilidad para comprender las particularidades y potencial productivo de los campos en todo el país. Con tecnología satelital de última generación, realizamos relevamientos exhaustivos y precisos. Nuestro equipo de expertos en tasación cuenta con amplio conocimiento técnico y brinda atención personalizada y servicio de calidad que cumple expectativas exigentes. Nos adaptamos a sus necesidades, con enfoque cercano para garantizar la mejor experiencia y resultados exitosos.",
    image: Image2,
  },
  {
    id: 2,
    name: "Tasaciones satelitales",
    text: "En Bullrich Campos, combinamos más de 30 años de experiencia en el uso de imágenes satelitales con técnicas de procesamiento avanzadas. Nuestro servicio de tasaciones satelitales ofrece información precisa y valiosa, identificando la calidad del suelo, riesgos de inundación, superficie desmontada y más. Nuestro equipo de expertos en teledetección analiza cultivos, montes, agua, caminos y construcciones, brindando un análisis de riesgo adicional. Confie en nuestra tecnología avanzada y conocimiento especializado para obtener tasaciones completas y confiables. Innovación, liderazgo y un servicio excepcional a su alcance.",
    image: Image3,
  },
  {
    id: 3,
    name: "Divisiones",
    text: "Brindamos seguridad y confianza en subdivisiones y particiones familiares. Con amplia experiencia, abordamos cuidadosamente cada escisión considerando aspectos más allá de lo económico. Garantizamos divisiones satisfactorias mediante tasaciones justas y actualizadas. Prestamos atención a cada detalle y presentamos proyectos que cumplen las premisas definidas. Aseguramos divisiones equitativas para una convivencia en equilibrio entre los propietarios. Estamos aquí para usted en subdivisiones y particiones familiares",
    image: Image4,
  },
  {
    id: 4,
    name: "Consultoria",
    text: "Nuestra vasta trayectoria y experiencia nos permite brindar un asesoramiento integral de máxima calidad. Abordamos todos los aspectos relevantes para la evaluación de terrenos rurales, desde los temas productivos hasta la posible rentabilidad. Consideramos también los aspectos societarios, comerciales, legales e impositivos. Esto le permite a nuestros clientes obtener una visión completa y detallada del inmueble rural, tomando decisiones acertadas basadas en datos confiables y seguros. Confíe en nosotros para tomar decisiones informadas sobre propiedades evaluadas.",
    image: Image5,
  },
]

const Main = () => {
  const [selected, setSelected] = useState(0)
  const [animationType, setAnimationType] = useState("fade-in")

  useEffect(() => {
    Aos.init()
  }, [])

  return (
    <section className="our-services-servicios">
      <div className="container-fluid">
        <h2 className="mb-5 mb-3 pb-lg-5 text-center">Nuestros Servicios</h2>
      </div>
      <div className="services">
        <div className="container-fluid h-100 d-flex align-items-center">
          <div className="options d-flex flex-lg-column flex-row justify-content-between">
            {SERVICES.map(({ name, id }) => (
              <h2
                onClick={() => setSelected(id)}
                className={"option " + (selected === id && "active")}
              >
                {name}
              </h2>
            ))}
          </div>
        </div>
        <div className="service-selected d-flex align-items-lg-center justify-content-center">
          <div className="content d-flex align-items-center">
            {SERVICES.map((service) => (
              <p className={selected === service.id && 'active'}>
                {service.text}
              </p>
            ))}
          </div>
        </div>
        {SERVICES.map((service) => (
            <img src={service.image} className={"image-selected " + (selected === service.id && 'active')} alt="Imagen principal perteneciente a un servicio" />
        ))}
      </div>
    </section>
  )
}

export default Main
