import React from "react"

const METHODOLOGIES = [
  {
    id: "01",
    name: "Trabajo previo",
    text: "Para recolectar datos, llevamos a cabo una profunda investigación. Esto implica el análisis de imágenes satelitales, consultas a las cartas de suelos del INTA y recopilación de información de campos previamente estudiados en la zona. También consideramos el material proporcionado por el propietario para obtener un análisis completo de las características zonales, clima, producción y tamaño de las explotaciones.",
  },
  {
    id: "02",
    name: "Recorrida a campo",
    text: "Con el fin de realizar un relevamiento exhaustivo, realizamos visitas al campo. Durante estas visitas, recorremos el terreno e identificamos los diferentes ambientes productivos. Además, analizamos los límites del terreno, las construcciones y su estado de mantenimiento. También tenemos en cuenta el valor de todas las mejoras fijas presentes. Para obtener una visión completa y precisa, utilizamos tecnología avanzada como drones, cámaras y planos.",
  },
  {
    id: "03",
    name: "Informe de tasación",
    text: "En nuestras evaluaciones de terrenos, consideramos tanto aquellos sin mejoras como aquellos con mejoras animadas. Además, describimos detalladamente el casco, teniendo en cuenta las mejoras relevantes. Valoramos las hectáreas por ambiente, considerando aspectos como el estado, vida útil y valor funcional de las mejoras inanimadas. Determinamos el valor del casco en relación a la tierra y otros usos, y elaboramos planos de alta calidad que incluyen una explicación detallada.",
  },
  {
    id: "04",
    name: "Análisis del mercado",
    text: "Tomamos en cuenta el contexto y situación del mercado, evaluando aspectos específicos de cada establecimiento para una valoración precisa a lo largo del tiempo. Consideramos elementos clave como calidad del suelo, ubicación, zona, aguas subterráneas, clima, mejoras, tipo de explotación y características diferenciales. Ofrecemos un análisis completo y preciso del valor del establecimiento, tomando en cuenta todos estos factores.",
  },
  {
    id: "05",
    name: "Anexo",
    text: "Una vez finalizado, entregamos una valuación detallada que incluye valores específicos de los establecimientos. Además, proporcionamos planos detallados de ubicación, ambientes y potreros, junto con imágenes satelitales que respaldan nuestro análisis. Nuestro informe completo garantiza una evaluación precisa y confiable.",
  },
]

const Methodology = () => {
  return (
    <section className="metodologia-servicios">
      <div className="container-fluid ">
        <h2 className="text-center">Nuestra metodología</h2>
        <span className="mt-lg-3 text-center d-block">
          PROCESO DE UNA TASACIÓN
        </span>
        <div className="row mt-lg-5 justify-content-center">
          <div className="col-lg-10 col-xxl-9">
            {METHODOLOGIES.map(method => (
              <div className="row method justify-content-center my-lg-5 py-lg-3" data-aos="fade-up" data-aos-once="false">
                <div className="col-lg-2">
                  <h1 className="bold">{method.id}</h1>
                </div>
                <div className="col-lg-3">
                  <h2 className="bold">{method.name}</h2>
                </div>
                <div className="col-lg-6 offset-lg-1">
                  <p>{method.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Methodology
