import * as React from "react"
import Layout from "../../components/v2/layout"
import Seo from "../../components/seo"
import Main from '../../components/v2/servicios/main.jsx'
import About from '../../components/v2/servicios/about.jsx'
import OurServices from '../../components/v2/servicios/our-services.js'
import Methodology from '../../components/v2/servicios/methodology.js'
import Contact from '../../components/v2/Contact'

//Components

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
      
  return(
  <Layout>
    <Seo title="Creciendo junto al campo desde 1867" description="Más de 150 años de experiencia en el sector rural. Conozca los campos más destacados de la Argentina, servicio de tasación satelital, divisiones y consultoría."/>
    <Main />
    <About />
    <OurServices />
    <Methodology />
    <Contact dark bigTitle={true} from='services' title="Solicite una entrevista para recibir asesoramiento personalizado sobre estos servicios." />
  </Layout>
)}

export default IndexPage
