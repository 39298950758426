import React from "react"
import Image from '../../../images/servicio-top.webp'
import BannerTop from "../../v1/Global/Modules/MediaPush/BannerTop"

const Main = () => {
  return (
    <section className="banner-top servicios">
      <BannerTop page={"TAS"}/>
      <img src={Image} alt="Portada principal de tasaciones"/>
      <div className="content text-center">
        <h1 className="white">Descubra la diferencia</h1>
      </div>
    </section>
  )
}

export default Main
