import React from "react"
import Image from "../../../images/servicio-top.png"
import Breadcrumbs from "../breadcrumbs"

const Main = () => {
  return (
    <section className="about-servicios">
      <div className="container-fluid">
        <div className="d-flex mb-5 pb-lg-5 w-100 justify-content-center">
          <Breadcrumbs
            props={[
              { name: "Bullrich Campos", route: "/", location: "" },
              { name: "Servicios", route: "", location: "" },
            ]}
          />
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <p>
              {" "}
              <div className="first"> N </div>uestro sello distintivo se basa en
              la atención personalizada y cercana que le brindamos a cada uno de
              nuestros clientes. Nos enorgullece ofrecer un servicio de compleja
              resolución con la máxima calidad. Para lograr los mejores
              resultados, creemos en la importancia de escuchar y conocer a
              todos nuestros clientes de manera individual, con el fin de
              asegurar el cuidado de cada detalle en nuestro trabajo. En
              Bullrich Campos, nos esforzamos por ofrecer una atención que
              garantice la máxima satisfacción en cada etapa del proceso
              inmobiliario rural.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
